.jplayer-player {
    width: 100%;
    height: 90px;
    position: fixed;
    left: 0;
    background: #181818;
    padding: 0 10px;
    z-index: 1;
    
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 16px;

    -webkit-user-select: none;  /* Chrome 49+ */
    -moz-user-select: none;     /* Firefox 43+ */
    -ms-user-select: none;      /* No support yet */
    user-select: none;
}

.jplayer-player > div {
    flex: 1;
}

.jplayer-player.position_top {
    top: 0px;
}

.jplayer-player.position_bottom {
    bottom: 0px;
}

.jplayer-main-options {
    width: 100%;
    height: 100%;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jplayer-options-container {
    margin-bottom: 12px;
    width: 224px;
    height: 32px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
}

.jplayer-timer-container {
    width: 100%;
    height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jplayer-options-button {
    color: #b3b3b3;
    position: relative;
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;

    margin: 0;
    padding: 0;
}

.jplayer-options-button:nth-child(3) {
    background: white;
    border-radius: 50%;
    color: #000;
}

.jplayer-timer-progress {
    color: rgb(179, 179, 179);
    min-width: 40px;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: normal;
    text-transform: none;
    height: 16px;
}

.jplayer-progressbar-wrapper {
    height: 12px;
    width: 100%;
    position: relative;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.jplayer-progressbar {
    width: 100%;
    height: 6px;
    background:#b3b3b3;
    border-radius: 24px;
    cursor: pointer;
}

.jplayer-progressbar-percent {
    width: 0%;
    cursor: pointer;
    height: 100%;
    background: rgba(0,0,0,.3);
}

.jplayer-extra-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    color: #b3b3b3;
    padding-right: 24px;
    max-width: 70%;
    margin-left: 20px;
}

.jplayer-extra-controls > a {
    color: #b3b3b3;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
}

.jplayer-extra-controls > div {
    color: #b3b3b3;
    position: relative;
    min-width: 32px;
    height: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: transparent;
    margin: 0px 4px;

    cursor: pointer;
}

.jplayer-extra-controls > div > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 4px;
}

.jplayer-volume {
    width: 93px;
    height: 12px;
    margin: 0;
    padding: 0px;
}

.jplayer-volume-wrapper > div {
    width: 100%;
    height: 4px;
}

.hide {
    display: none;
}

.jplayer-info-wrapper {
    width: 100%;
    height: 56px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    color:#b3b3b3;

    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.jplayer-info-wrapper > div:nth-child(1) {
    width: 56px;
    height: 100%;
    margin-left: 3px;
    margin-top: 6px;
    flex-grow: 0;
    flex-shrink: 0;
}

.jplayer-info-wrapper > div:nth-child(1) > img {
    width: 100%;
    height: 100%;
}

.jplayer-info-wrapper > div:nth-child(2) {
    width: calc(100% - 50px);
    height: 32px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 14px;
    vertical-align: baseline;
    overflow: hidden;
}

.jplayer-info-wrapper > div:nth-child(2) * {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: normal;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
}

.jplayer-info-wrapper > div:nth-child(2) a {
    font-weight: 600;
    color: inherit;
    text-decoration: none;
}

.jplayer-info-wrapper > div:nth-child(2) span {
    font-size: .8rem;
}

@media only screen and (max-width: 576px) {
    .jplayer-player {
        padding: 0px;
        height: 120px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .jplayer-options-container {
        margin-top: 6px;
    }

    .jplayer-player > div:nth-child(1) {
        width: 100%;
        display: flex;
        margin-top: 5px;
    }

    .jplayer-player > div:nth-child(1) > div {
        flex: 3;
    }

    .jplayer-player > div:nth-child(1) > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: end;
        flex: 1;
    }

    .jplayer-extra-controls {
        padding-right: 15px;
    }

    .jplayer-extra-controls > div:nth-child(2) {
        display: none;
    }

    .jplayer-player > div:nth-child(2) {
        width: 85%;
    }

    .jplayer-player > div:nth-child(3), .jplayer-info-wrapper > div:nth-child(1) {
        display: unset;
    }

    .jplayer-timer-container {
        height: 5px;

        position: absolute;
        top: 0;
    }

    .jplayer-progressbar-wrapper {
        height: 100%;
    }

    .jplayer-progressbar {
        height: 100%;
        border-radius: unset;
    }

    .jplayer-timer-progress {
        display: none;
    }
}
